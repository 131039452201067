@use "sass:map";
@use "../settings/colors";
@use "../settings/spaces";

.c-main-statistic {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: map.get(spaces.$general, "md");
    height: 100%;
    text-align: center;

    &__value {
        font-size: 3rem;
        font-weight: 600;
        line-height: 1;
        width: 100%;

        &--center {
            align-items: center;
            display: flex;
            justify-content: center;
        }
    }

    &__description {
        color: #b2b2b2;
    }

    &__icon {
        height: 2rem;
    }
}

@use "../settings/spaces";

.c-data-query {
    background-color: rgba(200, 200, 200, 0.2);
    border-radius: 0.25rem;
    font-size: 85%;
    margin: 0;
    padding: 0.2em 0.4em;

    &__string {
        color: #7239ea;
    }

    &__number {
        color: #009ef7;
    }

    &__keyword {
        color: #ffc700;
    }

    &__bracket {
        color: grey;
    }
}


::-webkit-scrollbar {
    height: 13px;
    width: 13px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.0375);
    box-shadow: inset 0 0 0.3125em rgba(0, 0, 0, 0.075);
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    border: 2px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:active {
    background-color: rgba(0, 0, 0, 0.4);
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    line-height: 1.66;
    scroll-behavior: smooth;
    scrollbar-color: rgba(0, 0, 0, 0.22) rgba(0, 0, 0, 0.075);
    scrollbar-width: thin;
}

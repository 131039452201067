@use "sass:map";
@use "../settings/colors";
@use "../settings/spaces";

.c-admin-layout {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__side-navigation {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &__main-content-container {
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        display: flex;
    }

    &__body {
        align-items: flex-start;
        background-color: map.get(colors.$theme, 'background');
        box-sizing: border-box;
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        gap: map.get(spaces.$general, "2xl");
        height: 100%;
        justify-content: center;
        opacity: 1;
        overflow: auto;
        padding: map.get(spaces.$general, "2xl");
        position: relative;
        transition: opacity 400ms;

        & > * {
            flex-grow: 1;
        }

        &--loading {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__topbar {
        background-color: white;
        border-bottom: 1px solid rgba(217, 219, 228, 0.5);
    }

    &__navigation {
        background-color: white;
        border-bottom: 1px solid rgba(217, 219, 228, 0.5);
        padding: map.get(spaces.$general, "sm") map.get(spaces.$general, "2xl");
        display: flex;
        justify-content: space-between;
        z-index: 1;
    }

    &__heading {
        font-size: 1.25rem;
        font-weight: 700;
        margin: 0;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 75rem;
    }
}
@use "sass:map";
@use "../settings/_border-radiuses" as br;
@use "../settings/_spaces" as sp;

.c-button {
    --font-size: 0.875rem;
    align-items: center;
    appearance: none;
    background-color: #0095e8;
    border-radius: map.get(br.$border-radiuses, "sm");
    border: solid 1px #0095e8;
    box-shadow: none;
    color: #fff;
    display: inline-flex;
    font-family: inherit;
    font-size: var(--font-size);
    font-weight: 600;
    justify-content: center;
    line-height: 1;
    outline: none;
    padding: 0.875rem 1.5rem;
    //text-transform: uppercase;
    transition: background-color 125ms ease-in, border-color 125ms ease-in;

    &:hover:not([disabled]) {
        background-color: #0078bd;
        border-color: #0078bd;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 4px #cbd6ee;
    }

    &[disabled] {
        opacity: 0.5;
    }

    &--shake {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
    }

    &--small {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
    }

    &--simple {
        background-color: #f1f1f1;
        border-color: #f1f1f1;
        color: grey;

        &:hover:not([disabled]) {
            background-color: #e8e8e8;
            border-color: #e8e8e8;
        }
    }

    &--danger {
        background-color: #f1416c;
        border-color: #f1416c;
        color: white;

        &:hover:not([disabled]) {
            background-color: #e33c63;
            border-color: #e33c63;
        }
    }

    &--success {
        background-color: #50cd89;
        border-color: #50cd89;
        color: white;

        &:hover:not([disabled]) {
            background-color: #42b777;
            border-color: #42b777;
        }
    }

    &__spinner {
        animation: rotate 2s linear infinite;
        height: 1em;
        margin: calc((var(--dimensions) - var(--font-size)) / 2 * -1) 0;

        & .path {
            animation: dash 1.5s ease-in-out infinite;
            opacity: 0.9;
            stroke-linecap: round;
        }
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

@use "sass:map";
@use "../settings/colors";
@use "../settings/spaces";

.c-marketplace-item {
    display: flex;
    flex-direction: column;
    gap: map.get(spaces.$general, "md");
    height: 100%;

    &__buttons {
        display: flex;
        justify-content: space-between;
    }

    &__description {
        margin: 0;
        height: calc(4 * spaces.$line-height * 1em);
    }

    &__header {
        display: flex;
        color: map.get(colors.$theme, "font");
    }

    &__icon {
        height: 5.5rem;
        object-fit: contain;
        width: 5.5rem;
        margin-right: map.get(spaces.$general, "md");
    }

    &__name {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 0;
    }

    &__author {
        font-size: 0.8rem;
        color: grey;
    }

    &__verified-icon {
        color: #009ef7;
        margin-right: 0.4em;
    }

    &__tag {
        color: grey;
        padding: 0.1875em 0.5em;
        border-radius: 3px;
        font-size: 0.8rem;
        background-color: #efefef;
    }
}

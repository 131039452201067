.c-plugin-timeline {
    &__row {
        display: flex;
        gap: 1rem;
        position: relative;
        border: dashed 1px #d9d9d9;
        border-radius: 10px;
        padding: 1rem;
        transition: background-color 100ms;

        &:hover {
            background-color: #fafafa;
        }
    }

    &__rows {
        position: relative;
        cursor: text;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__events {
        flex-grow: 1;
        height: 5rem;
        position: relative;
        min-width: 0; // This for recharts responsive container

        //&::before {
        //    border-color: #e3e3e3;
        //    border-style: dashed;
        //    border-width: 1px;
        //    content: "";
        //    height: 0px;
        //    inset: 0;
        //    margin: auto 0;
        //    position: absolute;
        //    transition: 200ms border-color;
        //}

        &:hover {
            &::before {
                border-color: grey;
            }
        }
    }

    &__event {
        animation: popout 370ms ease-out;
        background-color: #009ef7;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        font-weight: 600;
        height: 3rem;
        inset: 0;
        line-height: 3rem;
        margin: auto 0;
        position: absolute;
        text-align: center;
        transition: 200ms transform;
        user-select: none;
        width: 3rem;

        &:hover {
            transform: scale(1.2);
        }
    }

    &__details {
        width: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 0.5rem;
    }

    &__plugin-logo {
        cursor: pointer;
        height: 4rem;
        object-fit: contain;
        transition: 200ms transform;
        width: 5rem;

        &:hover {
            transform: scale(1.2);
        }
    }

    &__time-select {
        background-color: #bebebe;
        height: 100%;
        position: absolute;
        top: 0;
        width: 1px;
        pointer-events: none;
    }

    &__chart-name {
        font-size: 0.8rem;
        color: grey;
        background-color: #f1f1f1;
        border-radius: 4px;
        display: block;
        width: 100%;
        text-align: center;
        padding: 0.2rem ;
        box-sizing: border-box;
        white-space: nowrap;
    }
}

@keyframes popout {
    from{transform:scale(0)}
    40%{transform:scale(1.2)}
    60%{transform:scale(0.8)}
    to{transform:scale(1)}
}
@use "sass:map";
@use "../settings/spaces";

.c-data-list {
    display: grid;
    gap: map.get(spaces.$general, "sm");
    grid-template-columns: auto auto;
    margin: 0;
    padding: 0;

    &__item {
        list-style: none;
        display: contents;
    }

    &__label {
        color: #a1a5b7;
    }

    &__value {
        color: #3f4254;
        font-weight: 600;
        text-align: right;
    }

    &__info-icon {
        margin-left: map.get(spaces.$general, "xs");
        cursor: help;
    }
}

@use "sass:map";
@use "../settings/spaces";

.c-dashboard {
    align-items: flex-start;
    box-sizing: border-box;
    display: grid;
    gap: map.get(spaces.$general, "2xl");
    grid-template-columns: 3fr 1fr;
    grid-template-rows: min-content;

    &__main-statistics {
        display: grid;
        gap: map.get(spaces.$general, "2xl");
        //grid-template-columns: repeat(5, 1fr);
        grid-template-columns: repeat(auto-fit, minmax(7.5rem, 1fr));
    }

    &__events {
        display: flex;
        flex-direction: column;
        gap: map.get(spaces.$general, "2xl");
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: span 2;
    }
}

@media only screen and (max-width: 90em) {
    .c-dashboard {
        display: flex;
        flex-direction: column;

        & > * {
            width: 100%;
        }

        &__events {
            order: 9;
        }
    }
}

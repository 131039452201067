@use "sass:map";
@use "../settings/_border-radiuses" as br;
@use "../settings/_spaces";

$input-vertical-padding: 0.625rem;
$input-border-width: 1px;
$hide-password-icon-height: 1rem;

.c-text-field {
    position: relative;

    &__input {
        background-color: #f5f8fa;
        border: solid $input-border-width #f5f8fa;
        border-radius: map.get(br.$border-radiuses, "sm");
        box-shadow: none;
        box-sizing: border-box;
        cursor: default;
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
        min-height: calc(2 * ($input-border-width + $input-vertical-padding) + 21px);
        outline: none;
        padding: $input-vertical-padding map.get(spaces.$general, "sm");
        position: relative;
        transition: 125ms background-color ease-in, 125ms border-color ease-in;
        width: 100%;

        &:focus {
            box-shadow: 0 0 0 4px #cbd6ee;
            outline: none;
        }

        &:hover {
            background-color: #eef3f7;
        }

        &--query {
            &::before {
                content: attr(placeholder);
                padding-top: $input-vertical-padding;
                position: absolute;
                top: 0;
                user-select: none;
            }
        }

        &:not(&--empty) + &--query {
            &::after {
                display: inline-block;
            }
        }
    }


    &__placeholder {
    }

    &__label {
        color: #3f4254;
        font-size: 0.9375rem;
        margin: 0 0 0.375rem 0;
    }

    &__description {
        color: #8a8b9a;
        margin: 0;
        font-size: 0.75rem;
    }

    &__input-container {
        position: relative;
    }

    &__show-password-icon {
        cursor: pointer;
        margin: auto;
        position: absolute;
        right: 0.5rem;
        top: calc(50% - ($hide-password-icon-height / 2));
        user-select: none;
        width: 1.5rem;
    }
}

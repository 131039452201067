@use "sass:map";
@use "../settings/spaces";

.c-plugin-dashboard {
    //align-items: start;
    display: flex;
    flex-direction: column;
    gap: map.get(spaces.$general, "2xl");
    //justify-content: start;

    &__charts {
        display: grid;
        gap: map.get(spaces.$general, "2xl");
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 20rem;
        width: 100%;
    }

    &__bottom-cards {
        align-items: flex-start;
        display: grid;
        gap: map.get(spaces.$general, "2xl");
        grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    }
}

@media only screen and (max-width: 90em) {
    .c-plugin-dashboard {
        &__charts {
            display: flex;
            flex-direction: column;

            & > * {
                width: 100%;
            }

            .recharts-responsive-container {
                min-height: 12.5rem;
            }
        }
    }
}

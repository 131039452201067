@use "sass:map";
@use "../settings/spaces";

.c-table {
    width: 100%;
    border-collapse: collapse;

    &__header {
        color: #bebebe;
        text-align: left;
        padding: map.get(spaces.$general, "xs") map.get(spaces.$general, "sm");
    }

    &__data-cell {
        padding: map.get(spaces.$general, "2xs") map.get(spaces.$general, "xs");
    }

    &__row {
        &:hover {
            & > .c-table__data-cell {
                background-color: #f8f8f8;
            }
        }
    }

    &__controls {
        display: flex;
        gap: 0.5rem;
        line-height: 1;
    }

    &__controls-icon {
        color: #3f4254;
        cursor: pointer;

        &:hover {
            color: black;
        }
    }
}

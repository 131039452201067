.c-signup {
    display: flex;
    background-color: #eeeeee;
    padding: 2rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/signup-bg.svg");
    background-size: cover;
    height: 100vh;
    position: relative;
    box-sizing: border-box;

    &__cover {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__cover-photo {
        width: 20rem;
    }

    &__form {
        width: 35rem;
        height: 100%;
        flex-shrink: 0;
    }
}

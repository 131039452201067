@use "sass:map";
@use "../settings/_border-radiuses" as br;
@use "../settings/_spaces";

@mixin card {
    background-color: #fff;
    border-radius: map.get(br.$border-radiuses, $key: "md");
    box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.02);
    display: flex;
    flex-direction: column;
    min-width: 0; // This for recharts responsive container
}

.c-card {
    @include card;

    &:empty {
        display: none;
    }

    &--spread {
        height: 100%;
        width: 100%;
    }

    &--skeleton {
        display: inline-block;
        position: relative;
        overflow: hidden;
        background-color: #ffffff;
        & > * {
            visibility: hidden;
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#eee, 0) 0,
                rgba(#eee, 0.2) 20%,
                rgba(#eee, 0.5) 60%,
                rgba(#eee, 0)
            );
            animation: shimmer 2s infinite;
            content: "";
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }

    &__header {
        border-bottom: 1px solid #f4f4f4;
        display: flex;
        justify-content: space-between;
        padding: map.get(spaces.$general, "md") map.get(spaces.$general, "lg");
    }

    &__body {
        flex-grow: 1;
        padding: map.get(spaces.$general, "md") map.get(spaces.$general, "lg");
        position: relative;
        overflow: auto;

        &--login {
            display: flex;
            flex-direction: column;
            gap: map.get(spaces.$general, "xl");
            //max-width: 17.5rem;
            padding: 4rem;
        }

        &--form {
            display: flex;
            flex-direction: column;
            gap: map.get(spaces.$general, "md");
        }

        &--loading {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--center {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 1rem;
        }
    }

    &__footer {
        border-top: 1px solid #f4f4f4;
        padding: map.get(spaces.$general, "md") map.get(spaces.$general, "lg");
    }

    &__title {
        font-size: 1.25rem;
        font-weight: 600;
    }

    &__toolbar {
        display: flex;
    }
}

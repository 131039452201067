@use "sass:map";
@use "../settings/spaces";

.c-marketplace-detail {
    display: flex;
    gap: map.get(spaces.$general, "xl");

    &__name {
        font-size: 2rem;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    &__logo {
        height: 7rem;
        width: 7rem;
        padding: 1rem;
        object-fit: contain;
    }

    &__info {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        gap: map.get(spaces.$general, "sm");
    }

    &__author {
    }

    &__description {
        margin: 0;
        margin-top: 1rem;
    }

    &__url {

    }

    &__verified-icon {
        color: #009ef7;
        margin-right: 0.4em;
    }
    
    &__tag {
        color: grey;
        padding: 0.1875em 0.5em;
        border-radius: 3px;
        font-size: 0.8rem;
        background-color: #efefef;
    }
}

@use "sass:map";
@use "../settings/colors";
@use "../settings/spaces";

.c-topbar {
    display: flex;
    align-items: center;
    padding: map.get(spaces.$general, "sm") map.get(spaces.$general, "2xl");

    &__logo {
        flex-grow: 0;
        max-height: 2rem;
    }

    &__controls {
        align-items: center;
        display: flex;
        justify-content: right;
        flex-grow: 1;
        gap: map.get(spaces.$general, "xl");
    }

    &__controls-icon {
        color: #8a8b9a;
        height: 1.5rem;
        transition: 200ms color;

        &:hover {
            color: map.get(colors.$theme, "font");
        }
    }

    &__item {
        line-height: 1;
    }
}

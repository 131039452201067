@use "sass:map";
@use "../settings/spaces";

.c-marketplace {

    &__plugins {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
    }

    &__plugin {

    }
}

@use "sass:map";
@use "../settings/border-radiuses";
@use "../settings/spaces";

.c-file-upload {
    align-items: center;
    background-color: #f1faff;
    border-radius: map.get(border-radiuses.$border-radiuses, "md");
    border: dashed 1px #0095e8;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: map.get(spaces.$general, "md");
    justify-content: center;
    padding: 1rem;

    &__icon {
        height: 3rem;
        color: #a8dffd;
        color: #009ef7;
    }

    &__title {
        font-size: 1rem;
        font-weight: 600;
    }
}

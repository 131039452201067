@use "sass:map";
@use "../settings/border-radiuses";
@use "../settings/colors";
@use "../settings/spaces";

.c-tooltip {
    background-color: white;
    border-radius: map.get(border-radiuses.$border-radiuses, $key: "md");
    box-shadow: 0 0 50px 0 rgba(82, 63, 105, 0.15);
    display: flex;
    gap: map.get(spaces.$general, "xs");
    flex-direction: column;
    padding: map.get(spaces.$general, "2xs") map.get(spaces.$general, "sm");
    z-index: 100;

    &__arrow {
        visibility: hidden;

        &::before {
            visibility: visible;
            content: "";
            transform: rotate(45deg);
        }

        &,
        &::before {
            position: absolute;
            width: 8px;
            height: 8px;
            background: inherit;
        }
    }

    &[data-popper-placement^="top"] > &__arrow {
        bottom: -4px;
    }

    &[data-popper-placement^="bottom"] > &__arrow {
        top: -4px;
    }

    &[data-popper-placement^="left"] > &__arrow {
        right: -4px;
    }

    &[data-popper-placement^="right"] > &__arrow {
        left: -4px;
    }
}

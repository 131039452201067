.c-paginator {
    display: flex;
    gap: 0.5rem;

    &__page {
        align-items: center;
        border-radius: 4px;
        display: flex;
        font-size: 0.9rem;
        height: 2rem;
        justify-content: center;
        text-align: center;
        user-select: none;
        min-width: 2rem;

        &--current {
            color: white;
            background-color: #009ef7;
        }

        &--empty {

        }

        &:not(&--current, &--empty):hover {
            background-color: rgba(232, 232, 232, 0.4);
            cursor: pointer;
        }
    }
}

.c-not-found {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;

    &__image {
        width: 20rem;
    }

    &__title {
        font-size: 2rem;
    }
}

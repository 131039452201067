.c-plugins-navigation {
    height: 100%;
    padding: 1rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__plugin {
        align-items:center;
        background-color: #f1f1f1;
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        height: 4.25rem;
        justify-content: center;
        padding: 0.4rem;
        position: relative;
        transition: transform 100ms;
        width: 4.25rem;

        &:hover {
            transform: scale(1.1);
        }

        &--active {
            outline: 3px solid #009ef7;
        }

        &--new {
            background-color: #50cd89;
            color: white;
            font-size: 2rem;
            vertical-align: center;
        }
    }

    &__icon {
        max-width: 100%;
        max-height: 100%;
    }

    &__no-icon {
        align-items: center;
        border-radius: 50%;
        color: white;
        display: flex;
        font-weight: 600;
        height: 2rem;
        justify-content: center;
        overflow: hidden;
        position: relative;
        width: 2rem;

        &:before {
            background-color: currentColor;
            content: "";
            inset: 0;
            opacity: 0.2;
            position: absolute;
        }
    }

    &__plugin-popup {
        background-color: white;
    }
}
@use "sass:map";
@use "../settings/spaces";

.c-plugin-installer {
    padding: map.get(spaces.$general, "2xl");
    display: flex;
    gap: map.get(spaces.$general, "2xl");
    flex-direction: column;

    &__content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    &__detail {
        padding: map.get(spaces.$general, "xl");
        border: solid 1px #d3d3d3;
        border-radius: 5px;
    }

    &__steps {
        display: grid;
        grid-template-columns: min-content auto;
    }

    &__step {
        display: contents;
    }

    &__step-number {
    }

    &__step-joint {
        position: relative;
        justify-self: center;
        height: 100%;
    }

    &__step-content {
        padding-bottom: 2rem;
    }

    &__step-name {
        align-self: center;
        margin: 0;
    }

    &__step-content,
    &__step-name {
        padding-left: 2rem;
    }

    &__navigation-number {
        align-items: center;
        background-color: #f1faff;
        border-radius: 50%;
        color: #009ef7;
        font-weight: 600;
        font-size: 1.5rem;
        display: flex;
        height: 4rem;
        justify-content: center;
        width: 4rem;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__joint {
        width: 2px;
        background-color: #e8f3f9;
        height: 100%;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: map.get(spaces.$general, "xl");
    }
}

.c-spinner {
    animation: rotate 2s linear infinite;

    &--main-statistic {
        height: 3rem;
    }

    &--page {
        height: 10rem;
    }

    &--card {
        width: 5rem;
    }

    &--inline {
        height: 1em;
        width: 1em;
    }

    & .path {
        animation: dash 1.5s ease-in-out infinite;
        opacity: 0.9;
        stroke-linecap: round;
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}

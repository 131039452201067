.c-project-select {
    border: solid 1px #009ef7;
    color: #009ef7;
    width: 10rem;
    text-align: center;
    border-radius: 5px;
    user-select: none;
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    cursor: not-allowed;

    &__value {
        flex-grow: 1;
    }
}

@use "sass:map";
@use "../settings/border-radiuses";
@use "../settings/colors";
@use "../settings/spaces";

.c-dropdown {
    background-color: white;
    border-radius: map.get(border-radiuses.$border-radiuses, $key: "md");
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    display: flex;
    gap: map.get(spaces.$general, "xs");
    flex-direction: column;
    padding: map.get(spaces.$general, "sm");
    z-index: 100;

    &--info {
        font-size: 0.8rem;
        max-width: 12rem;
        text-align: center;
    }

    &__option {
        color: map.get(colors.$theme, "font");
        border-radius: map.get(border-radiuses.$border-radiuses, $key: "sm");
        padding: map.get(spaces.$general, "xs") map.get(spaces.$general, "sm");
        text-decoration: none;

        &:hover {
            background-color: rgba(100,100,100,0.4);
        }
    }
}
